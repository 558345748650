<template>
<div>
  <Row :gutter="10">
    <Col span="24" style="text-align: right">
      <Button type="primary" @click="add()">新增</Button>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="no" slot-scope="{index}">{{index + 1}}</template>
        <template slot="preview" slot-scope="{row}">
<!--          <div>{{row.swiper_url}}</div>-->
          <img :src="row.swiper_url" alt="" style="width: 20%">
        </template>
        <template slot="order" slot-scope="{row}">
          <InputNumber :min="0" v-model="row.swiper_order" @on-change="onOrderChange(row.swiper_id, $event)"></InputNumber>
        </template>
        <template slot="action" slot-scope="{row}">
          <Button type="error" @click="del(row.swiper_id)">删除</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>

  <Modal v-model="modal" width="500" :mask-closable="false">
    <Form ref="formCustom" :label-width="80" >
      <FormItem label="图片" prop="passwd">
        <Upload
            name="img"
            ref="upload"
            :show-upload-list="false"
            :format="['jpg','jpeg','png']"
            :on-format-error="handleFormatError"
            :before-upload="handleBeforeUpload"
            action=""
            style="display: inline-block;width:58px;">
          <Button icon="ios-cloud-upload-outline">点此上传图片</Button>
        </Upload>
        <div style="width: 75%;height: 200px;margin-top: 10px" v-if="url">
          <img :src="url" alt="" style="width: 100%">
        </div>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="primary" @click="submit()">确定</Button>
    </div>
  </Modal>
</div>
</template>

<script>
import lrz from "lrz";
import _ from 'lodash'
import api from "../../api/setup";

export default {
  name: "Swiper",
  data: function(){
    return {
      baseUrl: api.baseUrl,
      modal: false,
      list:[],
      searchKey: {
        page: 1,
        pagesize: 30
      },
      total: 0,
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '预览', slot: 'preview'},
        {title: '顺序', slot: 'order', width: 100},
        {title: '操作', slot: 'action', width: 120, align: 'center'}
      ],
      article_id: '',
      article_list: [],
      url: '',
    }
  },
  methods: {
    onOrderChange: _.debounce(function(id, e){
      this.$http.patch(api.swiper, {id:id, order: e}).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功')
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    }, 1000),
    // handleSearch: _.debounce(function(value){
    //   this.$http.get(api.articleList, {params: {article_name: value}}).then(response => {
    //     if(response.data.success) {
    //       this.article_list = response.data.res;
    //     } else {
    //       this.$Message.error(response.data.err);
    //       console.log(response);
    //     }
    //   })
    // }, 1500),
    submit: function(){
      this.$http.post(api.swiper, {url: this.url, id: this.article_id}).then(response => {
        if(response.data.success) {
          this.$Message.success('添加成功')
          this.list = response.data.res;
          this.total = response.data.count;
          this.modal = false;
          this.url = '';
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    getList: function(){
      this.$http.get(api.swiper).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    add:function(){
      this.modal = true
    },
    del: function(id){
      this.$Modal.confirm({
        content: '<p>确实要删除这一项吗？</p>',
        onOk: () => {
          this.$http.delete(api.swiper, {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.$Message.success('删除成功');
              this.getList();
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });
    },
    handleFormatError: function(){
      this.$Message.error('请上传图片格式的文件。');
    },
    handleBeforeUpload (file) {
      const that = this;
      lrz(file,{fieldName: 'file'}).then(res =>{
        that.$http.post(api.uploadSwiper, res.formData).then(response => {
          if(response.data.success) {
            this.url = response.data.path
          } else {
            this.$Message.error(response.data.err);
            console.log(response);
          }
        })
      })
      return false;
    },
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>