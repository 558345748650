var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Row',{attrs:{"gutter":10}},[_c('Col',{staticStyle:{"text-align":"right"},attrs:{"span":"24"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v("新增")])],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{attrs:{"span":"24"}},[_c('Table',{attrs:{"stripe":"","border":"","columns":_vm.columns,"data":_vm.list},scopedSlots:_vm._u([{key:"no",fn:function(ref){
var index = ref.index;
return [_vm._v(_vm._s(index + 1))]}},{key:"preview",fn:function(ref){
var row = ref.row;
return [_c('img',{staticStyle:{"width":"20%"},attrs:{"src":row.swiper_url,"alt":""}})]}},{key:"order",fn:function(ref){
var row = ref.row;
return [_c('InputNumber',{attrs:{"min":0},on:{"on-change":function($event){return _vm.onOrderChange(row.swiper_id, $event)}},model:{value:(row.swiper_order),callback:function ($$v) {_vm.$set(row, "swiper_order", $$v)},expression:"row.swiper_order"}})]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{attrs:{"type":"error"},on:{"click":function($event){return _vm.del(row.swiper_id)}}},[_vm._v("删除")])]}}])})],1)],1),_c('Row',{staticStyle:{"margin-top":"15px"}},[_c('Col',{staticStyle:{"text-align":"center"},attrs:{"span":"24"}},[_c('Page',{attrs:{"show-sizer":"","show-elevator":"","show-total":"","total":_vm.total,"current":_vm.searchKey.page,"page-size":_vm.searchKey.pagesize},on:{"on-change":_vm.pageChange,"on-page-size-change":_vm.pageSizeChange}})],1)],1),_c('Modal',{attrs:{"width":"500","mask-closable":false},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('Form',{ref:"formCustom",attrs:{"label-width":80}},[_c('FormItem',{attrs:{"label":"图片","prop":"passwd"}},[_c('Upload',{ref:"upload",staticStyle:{"display":"inline-block","width":"58px"},attrs:{"name":"img","show-upload-list":false,"format":['jpg','jpeg','png'],"on-format-error":_vm.handleFormatError,"before-upload":_vm.handleBeforeUpload,"action":""}},[_c('Button',{attrs:{"icon":"ios-cloud-upload-outline"}},[_vm._v("点此上传图片")])],1),(_vm.url)?_c('div',{staticStyle:{"width":"75%","height":"200px","margin-top":"10px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.url,"alt":""}})]):_vm._e()],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }